<template>
  <section class="landing-page">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="../../index.html"><img src="../../assets/images/logo.svg" alt=""></a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="mdi mdi-menu"></span>
              </button>
              <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav">
                  <li class="nav-item active">
                    <a class="nav-link" href="javascript:void(0);">HOME</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);">PRICING</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);">CONTACT</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="row py-sm-5 py-0">
          <div class="col-lg-7 banner-content">
            <h1 class="mr-2 text-white"> All you need for creating dashboard UI and complex web interfaces. </h1>
            <h3 class="font-weight-light text-white pt-2 pb-5"> Star Admin Template </h3>
            <div class="my-5">
              <button class="btn btn-primary btn-lg mr-2 mb-3">VIEW ALL DEMO</button>
              <button class="btn btn-danger btn-lg ml-2 mb-3">BUY NOW</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details-wrapper">
      <div class="container">
        <!-- insertion des "éléments" et des conditions d'affichage -->
        <div class="row">
          <div class="col-md-12">
              <card-pagination v-for="(section, sectionId) in page" :key="sectionId" :title="section.sectionName">
                  <!-- Card -->
                  <span v-if="section.element == 'card'">
                      <product-card :sectionTitle="section.sectionName" :entitytype="section.entitytype" :title="section.constrains['element.title']" :price="section.constrains['element.price']"></product-card>
                  </span>
                  <!-- Carousel -->
                  <span v-else-if="section.element == 'carousel'">
                      <carousel :sectionTitle="section.sectionName" :entitytype="section.entitytype" :title="section.constrains['element.title']" :description="section.constrains['element.description']"/>
                  </span>
                  <!-- Figcaption -->
                  <span v-else-if="section.element == 'figcaption'">
                      <Figcaption :sectionTitle="section.sectionName" :entitytype="section.entitytype" :title="section.constrains['element.title']" :description="section.constrains['element.description']"></Figcaption>
                  </span>
                  <span v-else>Pas d'élément pour les cards</span>
              </card-pagination>
          </div>
        </div>
      </div>
    </div>
    
    <footer>
      <div class="border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <img class="img-fluid" src="../../assets/images/logo.svg" alt="">
              <div class="d-flex pt-5 pb-5">
                <div class="social-icons bg-info text-white"><span class="mdi mdi-facebook"></span></div>
                <div class="social-icons bg-secondary text-white"><span class="mdi mdi-pinterest"></span></div>
                <div class="social-icons bg-danger text-white"><span class="mdi mdi-dribbble"></span></div>
                <div class="social-icons bg-info text-white"><span class="mdi mdi-twitter"></span></div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="d-flex">
                <i class="mdi mdi-cellphone-android"></i>
                <h6 class="font-weight-normal">(0000) 1234 5678</h6>
              </div>
              <div class="d-flex">
                <i class="mdi mdi-settings"></i>
                <h6 class="font-weight-normal">support@staradmin.org</h6>
              </div>
              <ul class="nav mt-3">
                <li class="nav-item">
                  <a class="nav-link pl-0" href="javascript:void(0);">Demos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Features</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Support</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Documentation</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3">
              <h3 class="text-white">Newsletter</h3>
              <p>To get the latest news from us please subscribe your email.</p>
              <div class="mt-3">
                <input type="email" class="form-control mb-2" placeholder="Enter your email"> </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-8">
            <h4 class="mt-3">Copyright © 2019 <a href="javascript:void(0);">bootstrapdash</a>. All rights reserved.</h4>
          </div>
          <div class="col-sm-4">
            <h4>Hand-crafted &amp; made with <i class="mdi mdi-heart text-danger d-inline-block"></i>
            </h4>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import page from '../json/page.json'
import Carousel from '../../components/element/carousel.vue'
import CardPagination from '../../components/pagination/card-pagination.vue'
import ProductCard from '../../components/element/product-card.vue'
import Figcaption from '../../components/metadata/figcaption.vue'
export default {
  components: {
    Carousel,
    CardPagination,
    ProductCard,
    Figcaption
  },
  name: 'preview',
  data () {
    return {
      page
    }
  },
  mounted () {
    console.log('json', this.page);
  }
}
</script>